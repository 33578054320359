import { BrowserRouter } from "react-router-dom";
import AppRoute from "../src/routes/routes";
import "./styles/style.scss";

function App() {
    return (
        <BrowserRouter>
            <AppRoute />
        </BrowserRouter>
    );
}

export default App;
