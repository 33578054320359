import React, { useState, useEffect, useRef } from "react";
import { Table, Select, Button, Input } from "antd";
import { MdRefresh } from "react-icons/md";
import {
    useGetProductMutation,
    useUpdateProductMutation,
} from "../../redux/slices/productSlice";

const Inventory = () => {
    const [loadings, setLoadings] = useState([]);
    const [getProduct, { isLoading }] = useGetProductMutation();
    const [updateProduct] = useUpdateProductMutation();
    const [productData, setproductData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [stockValues, setStockValues] = useState({});
    const hasRunEffect = useRef(false);
    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        getProduct()
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.id,
                    imageUrl: item.image,
                    vendor:item.vendor_name?item.vendor_name:'N/A',
                    company_name: item.company_name?item.company_name:'N/A',
                    name: item.item_name,
                    category: item.category.name,
                    category_id: item.category_id,
                    stock: item.quantity,
                }));
                setproductData(formattedData);
                setFilteredData(formattedData);
                const initialStockValues = {};
                formattedData.forEach((item) => {
                    initialStockValues[item.key] = item.stock;
                });
                setStockValues(initialStockValues);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }, []);

    const handleStockChange = (e, key) => {
        setStockValues({
            ...stockValues,
            [key]: e.target.value,
        });
    };
    
    const enterLoading = async (value, index) => {
        let data = {
            product_id: value.key,
            item_name: value.name,
            company_name: value.company_name,
            quantity: Number(stockValues[value.key]),
        };
        await updateProduct({ productdata: data, id: value.key });
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 3000);
    };

    const tableColumns = [
        {
            title: "Product",
            dataIndex: "companyImg",
            key: "companyImg",
            render: (text, record) => (
                <div className="productBox" style={{ display: "flex", alignItems: "center" }}>
                    <div className="imgBox" style={{ marginRight: "20px" }}>
                    {record?.imageUrl ? (
                        <img
                            src={record.imageUrl}
                            alt={record.name}
                            className="img-fluid"
                            style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px" }}
                        />
                    ) : (
                        <p className="prouct-image-text">Product Image</p>
                    )}
                        
                    </div>
                    <span style={{textTransform: 'capitalize', fontWeight: '600'}}>{record.name}</span>
                </div>
            ),
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
            key: "company_name",
            sorter: (a, b) => a.company_name.length - b.company_name.length,
        },
        {
            title: "Vendor name",
            dataIndex: "vendor",
            key: "vendor",
            sorter: (a, b) => a.vendor.length - b.vendor.length,
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            sorter: (a, b) => a.category.length - b.category.length,
        },
        {
            title: "In Stock",
            dataIndex: "stock",
            key: "stock",
            render: (text, record) => (
                <Input
                    type="number"
                    value={stockValues[record.key]}
                    onChange={(e) => handleStockChange(e, record.key)}
                    className="formControl"
                    style={{ width: "150px" }}
                />
            ),
            sorter: (a, b) => a.stock - b.stock,
        },
        {
            title: "Update Stock",
            dataIndex: "update",
            key: "update",
            render: (text, record, index) => {
                return (
                    <Button
                        type="primary"
                        icon={<MdRefresh />}
                        loading={loadings[index]}
                        onClick={() => enterLoading(record, index)}
                        className="updateBtn"
                    />
                );
            },
        },
    ];

    const handleCategoryChange = (value) => {
        const filtereddata = productData.filter((item) => item.category_id === value);
        setFilteredData(filtereddata)
    }
        //   For searching the elements in the SearchBar
        const handleProductSearch = (event) => {
            const searchText = event.target.value.toLowerCase().trim();

            const filteredData = productData.filter((item) =>
                item.name.toLowerCase().includes(searchText) ||
                item.vendor.toLowerCase().includes(searchText) ||
                item.company_name.toLowerCase().includes(searchText)

            );
            setFilteredData(filteredData);
        };
    return (
        <div className="wrapper productCatalogWrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Inventories List</h4>
                            <div className="rightWrapper">
                            <Input
                                placeholder="Search here"
                                onChange={handleProductSearch}
                                className="homesearchInput"
                            />
                            <Select className="formSelect" placeholder="Choose Category" onChange={handleCategoryChange}>
                                <Select.Option value={1}>Injectable</Select.Option>
                                <Select.Option value={2}>Supplies</Select.Option>
                                <Select.Option value={3}>Medications</Select.Option>
                                <Select.Option value={4}>Skin Care</Select.Option>
                            </Select>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper table-responsive">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredData}
                                pagination={true}
                                loading={isLoading}
                                showSorterTooltip={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inventory;