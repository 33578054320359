import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import Swal from 'sweetalert2'
const ResetPassword = () => {
  // const navigate = useNavigate();
  // const dispatch=useDispatch();
  const [form] = Form.useForm();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const onFinish = async (values) => {
    console.log("Sucess", values);
    form.resetFields();

    // if(response.error){
    //     Swal.fire({
    //         title: response.error.data.message,
    //         icon: "error",
    //         confirmButtonText: 'OK'
    //     });
    //     return;
    // }

    // navigate("/login");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    const allFieldsFilled =
      values.email && values.password && values.password_confirmation;
    setIsButtonDisabled(!allFieldsFilled);
  };
  // Function for validating the Password
  const validatePassword = (_, value) => {
    const uppercaseRegex = /^(?=.*[A-Z])/;
    const numberRegex = /^(?=.*\d)/;
    const minLength = 8; // Minimum password length

    if (
      value &&
      (!uppercaseRegex.test(value) ||
        !numberRegex.test(value) ||
        value.length < minLength)
    ) {
      return Promise.reject(
        new Error(
          `Password should contain at least one uppercase letter, one number, and be at least ${minLength} characters long.`
        )
      );
    }

    return Promise.resolve();
  };
  return (
    <div className="authWrapper">
      <div className="innerWrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <h4 className="cardTitle">Lifeline Inventory</h4>
              <div className="card authCard">
                <div className="card-body">
                  <h5 className="title">Forget Password</h5>
                  <div className="formWrapper">
                    <Form
                      form={form}
                      name="loginForm"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      onValuesChange={handleFormChange}
                      autoComplete="off"
                      layout="vertical"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input
                          className="formControl"
                          placeholder="Enter your email"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Password"
                        name="password"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                          {
                            validator: validatePassword,
                          },
                        ]}
                      >
                        <Input.Password
                          className="formControl"
                          placeholder="Enter password here"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Confirm Password"
                        name="password_confirmation"
                        dependencies={["password"]}
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Confirm password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "The two passwords that you entered do not match!"
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          className="formControl"
                          placeholder="Enter password here"
                        />
                      </Form.Item>

                      <Form.Item className="text-center mb-4 w-100">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="authBtn w-100"
                          disabled={isButtonDisabled}
                        >
                          Update Password
                        </Button>
                      </Form.Item>

                      <p className="orText text-capitalize">
                        Don't have an account?{" "}
                        <Link to={"/register"}>Sign Up</Link>
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
