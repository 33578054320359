import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import Sidebar from '../Sidebar/sidebar';
import Footer from '../Footer/footer';

const { Content } = Layout;

const SidebarLayout = () => {
    return (
        <Layout>
            <Sidebar />
            <Content className="contentWrapper">
                <Outlet />
                <Footer />
            </Content>
        </Layout>
    );
};

export default SidebarLayout;