import React, { useState, useEffect, useRef } from 'react';
import { Table, Select } from "antd";
import { useGetAppointmentMutation } from '../../redux/slices/patientSlice';
import moment from 'moment';

const { Option } = Select;

const PatientReengage = () => {
  const [getAppointment, { isLoading }] = useGetAppointmentMutation();
  const [appointmentData, setAppointmentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null); 
  const hasRunEffect = useRef(false);

  const getAppointments = async () => {
    try {
      const res = await getAppointment();
      const today = moment();

      const defaultData = res.data.filter(item => {
        const updatedAt = moment(item.updated_at);
        const daysDifference = today.diff(updatedAt, 'days');
        return daysDifference > 45;
      });

      const formattedData = defaultData.map((item) => ({
        key: item.id,
        patient_name: item.patient_name,
        appointment_id: item.appointment_id,
        service_title: item.service_title ? item.service_title : '-',
        service_category: item.service_category,
        event_type: item.event_type,
        booked: item.online_versus_inhouse,
        booktime: moment(item.created_at).format('MM/DD/YY hh:mm:ss A'),
        lastseen: moment(item.updated_at).format('MM/DD/YY'),
        status: item.booking_status
      }));

      setAppointmentData(formattedData);
      setFilteredData(formattedData);
    } catch (error) {
      console.error(error, "error");
    }
  };

  useEffect(() => {
    if (hasRunEffect.current) return;
    hasRunEffect.current = true;
    getAppointments();
  }, []);

  const handleDaysFilter = (value) => {
    const today = moment();
    const days = value === "moreThan60" ? 60 : 45;
    const filtered = appointmentData.filter(item => {
      const updatedAt = moment(item.lastseen, 'MM/DD/YY');
      const daysDifference = today.diff(updatedAt, 'days');
      return daysDifference > days && (categoryFilter ? item.service_category === categoryFilter : true);
    });
    setFilteredData(filtered);
  };

  const handleCategoryFilter = (value) => {
    if (value === "Tirzepatide follow up") {
      setCategoryFilter(prev => (prev === value ? null : value)); 
    } else {
      setCategoryFilter(value);
    }

    const filtered = appointmentData.filter(item => {
      return (value ? item.service_title === value : true);
    });
    setFilteredData(filtered);
  };

  const tableColumns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Booking",
      dataIndex: "booked",
      key: "booked",
      sorter: (a, b) => a.booked.length - b.booked.length,
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
      sorter: (a, b) => a.event_type.length - b.event_type.length,
    },
    {
      title: "Service Title",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.length - b.service_title.length,
    },
    {
      title: "Category",
      dataIndex: "service_category",
      key: "service_category",
      sorter: (a, b) => a.service_category.length - b.service_category.length,
    },
    {
      title: "Booking Time",
      dataIndex: "booktime",
      key: "booktime",
      sorter: (a, b) => a.booktime.length - b.booktime.length,
    },
    {
      title: "Last Updated",
      dataIndex: "lastseen",
      key: "lastseen",
      sorter: (a, b) => a.lastseen.length - b.lastseen.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div className="wrapper campaignWrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar d-flex justify-content-between">
              <h4>Campaigns</h4>
              <div className="d-flex">
                <Select
                  placeholder="Filter by Days"
                  onChange={handleDaysFilter}
                  style={{ width: 200, marginRight: 16 }}
                >
                  <Option value="moreThan30">More than 45 Days</Option>
                  <Option value="moreThan60">More than 60 Days</Option>
                </Select>
                <Select
                  placeholder="Service Category"
                  onChange={handleCategoryFilter}
                  style={{ width: 200 }}
                >
                  <Option value="Tirzepatide follow up">Tirzepatide follow up</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="tableWrapper table-responsive">
              <Table
                className="productTable"
                columns={tableColumns}
                dataSource={filteredData}
                pagination={true}
                loading={isLoading}
                showSorterTooltip={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientReengage;
