import React, { useState, useEffect, useRef } from 'react';
import { Select, Input, Table, Button } from 'antd';
import { FaFilter } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useGetProductMutation, useCreateOrderMutation, useGetOrderMutation } from '../../redux/slices/productSlice';
import { useGetAppointmentMutation } from '../../redux/slices/patientSlice';

import moment from 'moment';

const { Option } = Select;

const OrderingForMedication = () => {
    const [showFilterSection, setShowFilterSection] = useState(false);
    const [getProduct, { isLoading: isLoadingProduct }] = useGetProductMutation();
    const [getAppointment, { isLoading }] = useGetAppointmentMutation();
    const [createOrder, { isLoading: isLoadingOrder }] = useCreateOrderMutation();
    const [getOrder, { isLoading: isLoadinggetOrder }] = useGetOrderMutation();
    const [appointmentData, setappointmentData] = useState([]);
    const [orderData, setorderData] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedPatientKeys, setSelectedPatientKeys] = useState([]);
    const hasRunEffect = useRef(false);
    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        getAppointment()
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.patient.id,
                    patient_id: item.patient.id,
                    name: item.patient_name ? item.patient_name : "-",
                    medication: item.online_versus_inhouse,
                    date: moment(item.updated_at).format('MM/DD/YY hh:mm:ss A'),

                }));
                setappointmentData(formattedData);
            })
            .catch((err) => {
                console.error("err", err);
            });
        getOrder()
            .then((res) => {
                return false;
                const formattedData = res.data.map((item) => ({


                }));
                setorderData(formattedData);
            })
            .catch((err) => {
                console.error("err", err);
            });

    }, []);
    const Patientcolumn = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Medication/Appointment Type',
            dataIndex: 'medication',
            key: 'medication',
        },
        {
            title: 'Date Last Ordered',
            dataIndex: 'date',
            key: 'date',
        },
    ];


    const Patientdetailscolumn = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Date Last Ordered',
            dataIndex: 'dateLastOrdered',
            key: 'dateLastOrdered',
        },
        {
            title: 'Select Medication Size',
            dataIndex: 'medicationSize',
            key: 'medicationSize',

        },
        {
            title: 'Order Quantity',
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',

        },
        {
            title: 'Delivery Type',
            dataIndex: 'deliveryType',
            key: 'deliveryType',

        },
        {
            title: 'Delivery Address',
            dataIndex: 'deliveryAddress',
            key: 'deliveryAddress',

        },
    ];

    const dataSource2 = [
        {
            key: '1',
            name: 'John Doe',
            dateLastOrdered: '',
            medicationSize: '',
            orderQuantity: '',
            deliveryType: '',
            deliveryAddress: '',
        },
        {
            key: '2',
            name: 'Jane Smith',
            dateLastOrdered: '',
            medicationSize: '',
            orderQuantity: '',
            deliveryType: '',
            deliveryAddress: '',
        },
    ];

    const showFilter = () => {
        setShowFilterSection((prev) => !prev);
    };
    const handleChangeId = (id) => {
        const numericId = Number(id);
        setSelectedCategoryId(numericId);
        getProduct()
            .then((res) => {
                const filtereddata = res.data.filter(
                    (item) => item.category_id === numericId
                );
                setFilteredProducts(filtereddata);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }
    const handleProductChange = (id) => {
        setSelectedProductId(id);
    };

    const handlePatientSelectionChange = (selectedRowKeys) => {
        setSelectedPatientKeys(selectedRowKeys);
    };
    const handleCreateOrder = async () => {

        const dataToSend = {
            category_id: selectedCategoryId,
            product_id: [selectedProductId],
            patient_id: selectedPatientKeys,
            date: "2024-10-18",
            data: {},
        }

        // return false;
        const response = await createOrder(dataToSend);
    };
    return (
        <div className="wrapper orderWrapper">
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Ordering</h4>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12">
                        <div className='topSection'>
                            <div className='leftSection'>
                                <Button className='listingButton'>Start New Order</Button>
                            </div>
                            <div className='rightSection'>
                                <div className='formItem'>
                                    <Select
                                        placeholder="Select Parent Category"
                                        style={{ width: '100%' }}
                                        className='formSelect'
                                        onChange={handleChangeId}
                                    >
                                        <Option value="1">Injectables</Option>
                                        <Option value="2">Supplies</Option>
                                        <Option value="3">Medication</Option>
                                        <Option value="4">Skin Care</Option>
                                    </Select>
                                </div>
                                <div className='formItem'>
                                    <Select
                                        placeholder="Products"
                                        style={{ width: '100%' }}
                                        className='formSelect'
                                        onChange={handleProductChange}
                                        loading={isLoadingProduct}
                                    >
                                        {filteredProducts.map((product) => (
                                            <Option key={product.id} value={product.id}>
                                                {product.item_name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='filterButton'>
                                    <button type='button' className='btn filterBtn' onClick={() => showFilter()}>
                                        {showFilterSection ? <IoClose /> : <FaFilter />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showFilterSection && (
                    <div className='card filterSection'>
                        <div className='card-header bg-white'>
                            <h4 className='card-title'>Filters</h4>
                        </div>
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-md-4">
                                    <Input placeholder="Date Last Visited" className='formControl' />
                                </div>
                                <div className="col-md-4">
                                    <Select placeholder="Exclude patients..." className='formSelect' style={{ width: '100%' }}>
                                        <Option value="exclude">Exclude all patients who have been ordered</Option>
                                    </Select>
                                </div>
                                <div className="col-md-4">
                                    <Select placeholder="Select date last ordered" className='formSelect' style={{ width: '100%' }}>
                                        <Option value="date">Select date last ordered</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-md-12 mb-5">
                    <Table
                        dataSource={appointmentData}
                        columns={Patientcolumn}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: handlePatientSelectionChange,
                        }}
                        pagination={true}
                        loading={isLoading}
                    />
                </div>
                <div className='col-md-12 mb-5'>
                    <div className='card tableCard'>
                        <div className='card-header'>
                            <Button className='listingButton' onClick={handleCreateOrder}>Create Order</Button>
                        </div>
                        <div className='card-body'>
                            <div className='tableWrapper table-responsive'>
                                <Table
                                    columns={Patientdetailscolumn}
                                    dataSource={dataSource2}
                                    pagination={true}
                                    bordered
                                    className="mb-4"
                                />
                            </div>
                            <div className='text-center'>
                                <Button className='listingButton'>Submit Order</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderingForMedication;
