import React from "react";
import { useNavigate } from "react-router-dom";
import CategoryImageOne from "../../assests/images/supply-chain.png";
import CategoryImageTwo from "../../assests/images/inject.png";
import CategoryImageThree from "../../assests/images/medicine.png";
import CategoryImageFour from "../../assests/images/face-cream.png";

const Category = () => {
    const navigate = useNavigate();
    const handleNavigate = (id) => {
        navigate("/products", { state: { categoryId: id } })
    }
    

    return(
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12 mb-5">
                        <div className="titleBar">
                            <h4>Categories</h4>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="categoryItem" onClick={() => handleNavigate(1)}>
                            <div className="categoryImage">
                                <img src={CategoryImageTwo} alt="category" className="img-fluid" />
                            </div>
                            <div className="categoryName">
                                <h5>Injectables</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="categoryItem" onClick={() => handleNavigate(2)}>
                            <div className="categoryImage">
                                <img src={CategoryImageOne} alt="category" className="img-fluid" />
                            </div>
                            <div className="categoryName">
                                <h5>Supplies</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="categoryItem" onClick={() => handleNavigate(3)}>
                            <div className="categoryImage">
                                <img src={CategoryImageThree} alt="category" className="img-fluid" />
                            </div>
                            <div className="categoryName">
                                <h5>Medications</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="categoryItem" onClick={() => handleNavigate(4)}>
                            <div className="categoryImage">
                                <img src={CategoryImageFour} alt="category" className="img-fluid" />
                            </div>
                            <div className="categoryName">
                                <h5>Skin Care</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category;