import React, { useState, useEffect, useRef } from "react";
import { Table, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { MdAddBox, MdDelete, MdEdit } from "react-icons/md";
import { useGetProductMutation,useDeleteProductMutation } from "../../redux/slices/productSlice";
import Swal from 'sweetalert2'
const Homepage = () => {
    const navigate = useNavigate();
    const [getProduct, { isLoading }] = useGetProductMutation();
    const [deleteProduct] = useDeleteProductMutation();
    const [productData, setproductData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const hasRunEffect = useRef(false);
    const getProductData=async()=>{
        try {
            const res=await getProduct();
            const formattedData = res.data.map((item) => ({
                key: item.id,
                imageUrl: item.image,
                name: item.item_name,
                vendor:item.vendor_name?item.vendor_name:'N/A',
                company:item.company_name?item.company_name:"N/A",
                category: item.category.name,
                price: `$${item.price}`,
                stock: item.quantity,
            }));
            setproductData(formattedData);
            setFilteredData(formattedData);  
        } catch (error) {
            console.error("error", error); 
        }
    }
    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        getProductData();
    }, []);

    const tableColumns = [
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            render: (text, record) => (
                <div className="productBox" style={{ display: "flex", alignItems: "center" }}>
                    <div className="imgBox" style={{ marginRight: "20px" }}>
                    {record?.imageUrl ? (
                        <img
                            src={record.imageUrl}
                            alt={record.name}
                            className="img-fluid"
                            style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px" }}
                        />
                    ) : (
                        <p className="prouct-image-text">Product Image</p>
                    )}
                        
                    </div>
                    <span style={{textTransform: 'capitalize', fontWeight: '600'}}>{record.name}</span>
                </div>
            ),
        },
        {
            title: "Company name",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.company.length - b.company.length,
        },
        {
            title: "Vendor name",
            dataIndex: "vendor",
            key: "vendor",
            sorter: (a, b) => a.vendor.length - b.vendor.length,
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            sorter: (a, b) => a.category.length - b.category.length,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            sorter: (a, b) => a.price.length - b.price.length,
        },
        {
            title: "In Stock",
            dataIndex: "stock",
            key: "stock",
            sorter: (a, b) => a.stock - b.stock,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="actionButton">
                    <button type="button" className="btn primaryBtn"  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/edit-product",{
                        state: {productId: record.key },
                      });
                }}>
                        <MdEdit style={{ cursor: "pointer", color: '#b4a099' }}/>
                    </button>
                    <button type="button" className="btn primaryBtn" onClick={(e) => {
                    e.stopPropagation(); 
                    handleDelete(record.key);
                }}>
                        <MdDelete
                            style={{ cursor: "pointer", color: '#ff0000' }}
                        />
                    </button>
                </div>

            ),
        },
    ];
    const handleDelete = async (id) => {
        try {
            Swal.fire({
                title: "Are you sure you want to delete this product?",
                showDenyButton: true,
                icon: "warning",
                confirmButtonText: "Yes",
                denyButtonText: "No",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await deleteProduct(id); // Await the deleteProduct function
                    const { product, message } = response.data;
    
                    if (product) {
                        Swal.fire({
                            title: message,
                            icon: "success",
                        });
                        getProductData(); // Refresh product list after successful delete
                    } else if (response.error) {
                        Swal.fire({
                            title: response.error.data.message,
                            icon: "error",
                            confirmButtonText: 'OK',
                        });
                        return;
                    }
                } else if (result.isDenied) {
                    Swal.fire({
                        title: "Your product is safe!",
                        icon: "info",
                        confirmButtonText: "OK",
                    });
                }
            });
        } catch (error) {
            console.error("Error deleting product:", error);
            Swal.fire({
                title: "Error deleting product",
                icon: "error",
                confirmButtonText: 'OK',
            });
        }
    };
    
    const handleAddProduct = () => {
        navigate("/add-product");
    };

    //   For searching the elements in the SearchBar
    const handleProductSearch = (event) => {
        const searchText = event.target.value.toLowerCase().trim();

        const filteredData = productData.filter((item) =>
            item.name.toLowerCase().includes(searchText) ||
            item.vendor.toLowerCase().includes(searchText) ||
            item.company.toLowerCase().includes(searchText) ||
            item.category.toLowerCase().includes(searchText) 

        );
        setFilteredData(filteredData);
    };

    const handleRowClick = (record) => {
        navigate("/product-details", {
            state: { productId: record.key },
        });
    };

    return (
        <div className="wrapper productCatalogWrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Products Catalog</h4>
                            <div className="rightWrapper">
                            <Input
                                placeholder="Search here"
                                onChange={handleProductSearch}
                                className="homesearchInput"
                            />
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleAddProduct()}
                                >
                                    <MdAddBox /> Add Product
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper table-responsive">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredData}
                                pagination={true}
                                loading={isLoading}
                                onRow={(record) => ({
                                    onClick: () => handleRowClick(record),
                                })}
                                showSorterTooltip={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
