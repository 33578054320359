import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Select, Input, Table, Button, Radio } from 'antd';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useAddAudienceMutation, useGetAudienceMutation, useGetFilteredpatientMutation,useGetListsMutation,useImportAudienceMutation } from '../../redux/slices/patientSlice';
import Swal from "sweetalert2";
const { Option } = Select;

const Audience = () => {
    const [audienceData, setAudienceData] = useState([]);
    const [type_of_audience, setAudienceType] = useState('one_time');
    const [appointment_type, setAppointmentType] = useState([]);
    const [last_visit_days, setLastVisit] = useState(null);
    const [status, setStatus] = useState(null);
    const [campaign_type, setCampaignType] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [list_id, setListId] = useState(null);
    const [name, setName] = useState('');
    const [ListIds, setListIds] = useState([]);
    const [getLists] = useGetListsMutation();
    const [importAudience] = useImportAudienceMutation();
    const [getFilteredpatient] = useGetFilteredpatientMutation();
    const [getAudience, { isLoading: AudienceLoading }] = useGetAudienceMutation();
    const [addAudience, { isLoading }] = useAddAudienceMutation();
    const hasRunEffect = useRef(false);
    const navigate = useNavigate();
    const [FilteredPatient, setFilteredPatient] = useState([]);
    const handleEdit = (key) => {
        navigate("/audienceuser", {
            state: { AudienceId: key },
        });
    }
    const columns = [
        { title: 'Audience Name', dataIndex: 'audienceName', key: 'audienceName' },
        { title: 'Vendor', dataIndex: 'vendor', key: 'vendor' },
        { title: 'Type of Campaign', dataIndex: 'typeOfCampaign', key: 'typeOfCampaign' },
        { title: 'Appointment Type', dataIndex: 'appointmentType', key: 'appointmentType' },
        { title: 'Audience Type', dataIndex: 'audienceType', key: 'audienceType' },
        { title: 'List ID', dataIndex: 'listId', key: 'listId' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: "Patients",
            key: "patient",
            render: (text, record) =>
                    <IoMdInformationCircleOutline
                        style={{ cursor: "pointer", color: '#b4a099' }}
                        onClick={() => handleEdit(record.key)}
                    />
        },
        {
            title: "Connection",
            key: "edit",
            render: (text, record) =>
                    <Button
                    className="listingButton me-1"
                        style={{ cursor: "pointer", }}
                        onClick={() => handleCreateConnection(record)}
                    >Create Connection </Button>
            
        },
    ];

    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        fetchAudienceData();
    }, []);

    const fetchAudienceData = () => {
        getAudience()
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.id,
                    audienceName: item.name,
                    appointmentType: item.appointment_type ? JSON.parse(item.appointment_type).join(', ') : '',
                    audienceType: item.type_of_audience,
                    status: item.status,
                    typeOfCampaign: item.campaign_type,
                    vendor: item.vendor,
                    listId: item.list_id,
                    audienceData:JSON.parse(item.audience)
                }));
                setAudienceData(formattedData);
            })
            .catch((err) => {
                console.error("Error fetching audience data:", err);
            });
    };

    const handledayChange = (day) => {
        setLastVisit(day);
        const filterData={
            days:day,
            service_category:appointment_type
        }
        getFilteredpatient(filterData).then((res) => {
            const formattedData = res.data.data.map((item) => ({
                first_name: item.patient_name,
                email: item.patient.email,
          
            }));
            setFilteredPatient(formattedData);
        }).catch((error) => {
            console.error("Error fetching filtered patients:", error);
        });
        getLists()
        .then((response) => {
            const ids = response.data.lists.map((el) => el.list_id);
            setListIds(ids);
        })
        .catch((error) => {
            console.error("Error fetching filtered patients:", error);
        });
    };

    const handleCreateAudience = async () => {
        const audienceDetails = {
            appointment_type,
            last_visit_days,
            status,
            campaign_type,
            vendor,
            list_id,
            name,
            type_of_audience,
            audience: FilteredPatient
        };

        const response = await addAudience(audienceDetails);

        if (response.error) {
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        const { data, message } = response.data
        if (data) {
            Swal.fire({
                title: message,
                icon: "success",
            });
            fetchAudienceData();
            window.location.reload();
        }

    };
    const handleCreateConnection =async (audienceData)=>{
        const payload={
            import_data: audienceData.audienceData            , // use the audienceData directly for import_data
            list_ids: [audienceData.listId]
            
        }
        const response = await importAudience(payload);

        if (response.error) {
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        const { data, message } = response.data
        if (data) {
            Swal.fire({
                title: message,
                icon: "success",
            });
            fetchAudienceData();
        }
    }
    return (
        <div className="wrapper orderWrapper container-fluid">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="titleBar d-flex justify-content-between">
                        <h4>Audiences Management</h4>
                    </div>
                </div>
            </div>

            <div className='card dashboardCard mb-4'>
                <div className='card-body'>
                    <div className="row">
                    <div className="titleBar d-flex justify-content-between">
                        <h5>Create Audience</h5>
                    </div>
                        <div className="col-md-3">
                            <div className='mb-3'>
                                <label>Appointment Type</label>
                                <Select
                                    placeholder="Select Appointment Type"
                                    className="formSelect w-100"
                                    mode="multiple"
                                    onChange={setAppointmentType}
                                >
                                    <Option value="skincare">Skin Care</Option>
                                    <Option value="injectables">Injectables</Option>
                                    <Option value="weightLoss">Weight Loss</Option>
                                    <Option value="other">Other</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='mb-3'>
                                <label>Last Visit</label>
                                <Select
                                    placeholder="Select Last Visit"
                                    className="formSelect w-100"
                                    onChange={handledayChange}
                                >
                                    <Option value="7">7 days</Option>
                                    <Option value="30">30 days</Option>
                                    <Option value="45">45 days</Option>
                                    <Option value="60">60 days</Option>
                                    <Option value="90">90 days</Option>
                                    <Option value="120">120 days</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Status</label>
                                <Select
                                    placeholder="Select Status"
                                    className="formSelect w-100"
                                    onChange={setStatus}
                                >
                                    <Option value="live">Live</Option>
                                    <Option value="paused">Paused</Option>
                                    <Option value="deleted">Delete</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Type of Campaign</label>
                                <Select
                                    placeholder="Select Campaign Type"
                                    className="formSelect w-100"
                                    onChange={setCampaignType}
                                >
                                    <Option value="email">Email</Option>
                                    <Option value="sms">SMS</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Vendor</label>
                                <Select
                                    placeholder="Select Vendor"
                                    className="formSelect w-100"
                                    onChange={setVendor}
                                >
                                    <Option value="strive">Strive</Option>
                                    <Option value="mckesson">Mckesson</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>List ID</label>
                                <Select
                                    placeholder="Select List ID"
                                    className="formSelect w-100"
                                    onChange={setListId}
                                >
                                   {ListIds.map((id) => (
                                        <Option key={id} value={id}>{id}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Name</label>
                                <Input
                                    placeholder="Name Audience"
                                    className="formControl w-100"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Audience Type</label><br />
                                <Radio.Group onChange={(e) => setAudienceType(e.target.value)} value={type_of_audience}>
                                    <Radio value="reoccurring">Recurring</Radio>
                                    <Radio value="one_time">One time</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="col-md-12 text-end my-4">
                            <Button
                                type="primary"
                                className="listingButton"
                                onClick={handleCreateAudience}
                                loading={isLoading}
                            >
                                Create Audience
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tableWrapper'>
                <Table
                    dataSource={audienceData}
                    columns={columns}
                    className="dashboardTable productTable"
                    scroll={{ x: 'scroll' }}
                    loading={AudienceLoading}
                    pagination={true}
                    showSorterTooltip={false}
                />
            </div>
        </div>
    );
};

export default Audience;
