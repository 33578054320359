import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isAuthenticated: false,
    user: null,
    email:null,
  };
  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      loginSuccess: (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
      },
      registerSuccess: (state, action) => {
        state.isAuthenticated = false;
      },
      userDetail : (state,action)=>{
        state.user = action.payload
      },
      userDeleteSuccess:(state)=>{
         state.isAuthenticated =false;
         state.user=null;
      },
      logout: (state) => {
        state.isAuthenticated = false;
        state.user = null;
      }
    
    },
  });
  
  export const { loginSuccess,registerSuccess,userDetail,userDeleteSuccess, logout } = authSlice.actions;
  export default authSlice.reducer;