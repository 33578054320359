import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Url } from "../../Helper";
const commonHeaders = {
    Accept: "application/json",
  };
  export const patientSlice = createApi({
    reducerPath: "patientSlice",
    baseQuery: fetchBaseQuery({ baseUrl: Api_Url }),
    endpoints: (builder) => ({
      getPatient: builder.mutation({
        query: () => {
          return {
            url: "/patients",
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),
      getFilteredpatient: builder.mutation({
        query: (filterData) => {
          return {
            url: `/patients/filter`,
            method: "POST",
            body: filterData,
            headers: commonHeaders,
          };
        },
      }),
      getAppointment: builder.mutation({
        query: () => {
          return {
            url: "/appointments-with-patient-name",
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),
      getPatientappointment: builder.mutation({
        query: (patientId) => {
          return {
            url: `/patients/${patientId}/appointments`,
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),
      addAudience: builder.mutation({
        query: (audiencedata) => {
          return {
            url: "/audiences",
            method: "POST",
            body: audiencedata,
            headers: commonHeaders,
          };
        },
      }),
      importAudience: builder.mutation({
        query: (importeddata) => {
          return {
            url: "/import-contacts",
            method: "POST",
            body: importeddata,
            headers: commonHeaders,
          };
        },
      }),
      getAudience: builder.mutation({
        query: () => {
          return {
            url: "/audiences",
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),
      getLists: builder.mutation({
        query: () => {
          return {
            url: "/contact-lists",
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),
      getAudiencePatient: builder.mutation({
        query: (id) => {
          return {
            url: `/audiences/${id}`,
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),

      
     
    }),
  });
  export const { useGetPatientMutation,useGetFilteredpatientMutation,useGetAppointmentMutation,useGetPatientappointmentMutation,useAddAudienceMutation,useImportAudienceMutation,useGetAudienceMutation,useGetListsMutation,useGetAudiencePatientMutation} = patientSlice;