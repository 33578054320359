import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Url } from "../../Helper";
const commonHeaders = {
  "Content-Type": "application/json; charset=UTF-8",
  Accept: "application/json",
};
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: Api_Url }),
  endpoints: (builder) => ({
    userRegister: builder.mutation({
      query: (newUser) => ({
        url: "/register",
        method: "POST",
        body: newUser,
        headers: commonHeaders,
      }),
    }),

    userLogin: builder.mutation({
      query: (userDetails) => ({
        url: "/login",
        method: "POST",
        body: userDetails,
        headers: commonHeaders,
      }),
    }),

    userProfile: builder.mutation({
      query: ({user,id}) => ({
        url: `user/${id}/update-profile`,
        method: "POST",
        body: user,
      }),
    }),
    
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/forgot_password",
        method: "POST",
        body: email,
        headers: commonHeaders,
      }),
    }),

    userDelete: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
        headers: commonHeaders,
      }),
    }),
  }),
});

export const {useUserRegisterMutation, useUserLoginMutation,useUserProfileMutation, useForgotPasswordMutation , useUserDeleteMutation } = authApi;
