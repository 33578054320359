import React, { useState, useEffect, useRef } from 'react';
import { Table, Input } from "antd";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useGetPatientMutation } from '../../redux/slices/patientSlice';

const Patient = () => {
    const navigate = useNavigate();
    const [getPatient, { isLoading }] = useGetPatientMutation();
    const [patientData, setpatientData] = useState([]);
    const [filteredPatientData, setFilteredPatientData] = useState([]);
    const hasRunEffect = useRef(false);
    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        getPatient()
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.id,
                    name: `${item.first_name} ${item.last_name}`,
                    email: item.email,
                    phone: item.phone,
                    address: item.address,
                    city: item.city,
                    state: item.state,
                    zip: item.zip,
                    appointment: item.appointment,
                }));
                setpatientData(formattedData);
                setFilteredPatientData(formattedData);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }, []);
    const tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            sorter: (a, b) => a.phone.length - b.phone.length,
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => a.address.length - b.address.length,
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
            sorter: (a, b) => a.city.length - b.city.length,
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            sorter: (a, b) => a.state.length - b.state.length,
        },
        {
            title: "Zip",
            dataIndex: "zip",
            key: "zip",
            sorter: (a, b) => a.zip.length - b.zip.length,
        },
        {
            title: "Info",
            key: "edit",
            render: (text, record) =>
                record.appointment == true ? (
                    <IoMdInformationCircleOutline
                        style={{ cursor: "pointer", color: '#b4a099' }}
                        onClick={() => handleEdit(record.key)}
                    />
                ) : null,
        },
    ];

    //   For searching the elements in the SearchBar
    const handlePatientSearch = (event) => {
        const searchText = event.target.value.toLowerCase().trim();

        const filteredData = patientData.filter((item) =>
            item.name.toLowerCase().includes(searchText)||
            item.email.toLowerCase().includes(searchText)||
            item.phone.toLowerCase().includes(searchText)||
            item.address.toLowerCase().includes(searchText)||
            item.city.toLowerCase().includes(searchText)||
            item.state.toLowerCase().includes(searchText)||
            item.zip.toLowerCase().includes(searchText)
        );
        setFilteredPatientData(filteredData);
    };

    const handleEdit = (key) => {
        navigate("/patientappointment", {
            state: { patientId: key },
        });
    }


    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Patients List</h4>
                            <Input
                                placeholder="Search here.."
                                onChange={handlePatientSearch}
                                className="homesearchInput"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper table-responsive">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredPatientData}
                                pagination={true}
                                loading={isLoading}
                                showSorterTooltip={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Patient;