import React, { useState } from 'react';
import { Select, Input, Table, Button, DatePicker } from 'antd';

const { Option } = Select;

const InventoryManagement = () => {
    const [selectedInventoryType, setSelectedInventoryType] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(null);

    const handleInventoryTypeChange = (value) => {
        setSelectedInventoryType(value);
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const handleProductChange = (value) => {
        setSelectedProduct(value);
    };

    const handleQuantityChange = (value) => {
        setQuantity(value);
    };

    const handleConfirmSelection = () => {
        console.log('Confirming selection:', {
            selectedInventoryType,
            selectedCategory,
            selectedProduct,
            quantity,
        });
    };

    const columns = [
        { title: 'Date', dataIndex: 'date', key: 'date' },
        { title: 'Provider', dataIndex: 'provider', key: 'provider' },
        { title: 'Product Name', dataIndex: 'productName', key: 'productName' },
        { title: 'Quantity Used', dataIndex: 'quantityUsed', key: 'quantityUsed' },
        { title: 'Starting Quantity', dataIndex: 'startingQuantity', key: 'startingQuantity' },
        { title: 'Ending Quantity', dataIndex: 'endingQuantity', key: 'endingQuantity' },
    ];

    const data = [
        { key: '1', date: '', provider: '', productName: '', quantityUsed: '', startingQuantity: '', endingQuantity: '' },
        { key: '2', date: '', provider: '', productName: '', quantityUsed: '', startingQuantity: '', endingQuantity: '' },
    ];

    return (
        <div className="wrapper orderWrapper">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="titleBar d-flex justify-content-between">
                        <h4>Inventories Management</h4>
                    </div>
                </div>
            </div>

            <div className="row mb-3 align-items-center">
                <div className="col">
                    <DatePicker placeholder="Select Date" className="formControl w-100" />
                </div>
                <div className="col">
                    <Select placeholder="Type of Inventory Usage" className="formSelect w-100" onChange={handleInventoryTypeChange}>
                        <Option value="add">Adding Inventory</Option>
                        <Option value="subtract">Subtracting Inventory</Option>
                    </Select>
                </div>
                <div className="col">
                    <Select placeholder="Category" className="formSelect w-100" onChange={handleCategoryChange}>
                        <Option value="medication">Medication</Option>
                        <Option value="injectables">Injectables</Option>
                    </Select>
                </div>
                <div className="col">
                    <Select placeholder="Product" className="formSelect w-100" onChange={handleProductChange}>
                        <Option value="product1">Product 1</Option>
                        <Option value="product2">Product 2</Option>
                    </Select>
                </div>
                <div className="col">
                    <Input placeholder="Quantity" type="number" className="formControl w-100" onChange={(e) => handleQuantityChange(e.target.value)} />
                </div>
                <div className="col">
                    <Button type="primary" className="listingButton" onClick={handleConfirmSelection}>Confirm Selection</Button>
                </div>
            </div>

            <div className='tableWrapper mt-4'>
                <Table className="dashboardTable productTable" columns={columns} dataSource={data} pagination={false} scroll={{ x: 'scroll'}} />
            </div>
        </div>
    );
};

export default InventoryManagement;
