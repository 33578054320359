import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {MdCategory, MdPerson, MdAutoAwesomeMosaic, MdAssessment  } from "react-icons/md";
import { FaHospitalUser } from "react-icons/fa6";
import { LuCalendarCheck2 } from "react-icons/lu";
import { BsFillCartPlusFill } from "react-icons/bs";
import { AiFillProduct,AiFillShop } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";
import Logo from  "../../assests/images/logo.svg"

const { Sider } = Layout;

const Sidebar = () => {

    const menuIitems = [
        {
            key: "/",
            icon: <MdAssessment />,
            label: <Link to="/">Dashboard</Link>,
        },
        {
            key: "/appointments",
            icon: <LuCalendarCheck2  />,
            label: <Link to={"/appointments"}>Appointments List</Link>,
        },
        {
            key: "/audience",
            icon: <BsPeopleFill />,
            label: <Link to={"/audience"}>Audiences Management</Link>,
        },
        {
            key: "/campaign",
            icon: <FaHospitalUser  />,
            label: <Link to={"/campaign"}>Campaigns</Link>,
        },
        {
            key: "/category",
            icon: <MdCategory />,
            label: <Link to="/category">Categories</Link>,
        },
        {
            key: "/inventory",
            icon: <MdAutoAwesomeMosaic />,
            label: <Link to={"/inventory"}>Inventories List</Link>,
        },
        {
            key: "/inventorymanagement",
            icon: <AiFillShop  />,
            label: <Link to={"/inventorymanagement"}>Inventories Management</Link>,
        },
        {
            key: "/my-account",
            icon: <MdPerson />,
            label: <Link to={"/my-account"}>My Account</Link>,
        },
        {
            key: "/ordering",
            icon: <BsFillCartPlusFill  />,
            label: <Link to={"/ordering"}>Ordering</Link>,
        },
        {
            key: "/patients",
            icon: <FaHospitalUser  />,
            label: <Link to={"/patients"}>Patients List</Link>,
        },
        {
            key: "/allproducts",
            icon: <AiFillProduct />,
            label: <Link to="/allproducts">Products Catalog</Link>,
        },   
    ];

    return (
        <Sider className="sidebar" width={300} breakpoint="lg" collapsedWidth="0">
            <div className="topSection">
                <div className="logoWrap">
                    <Link to={"/"}>
                        <img className="img-fluid" src={Logo} alt="logo" />
                    </Link>
                </div>
                <div className="menuWrap">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={["1"]}
                        items={menuIitems}
                    />
                </div>
            </div>
            {/* <div className="bottomSection">
                <div className="signInWrap">
                    <p className="mainText">Sign in to test this template</p>
                    <p className="subText">If you included sample data, sign in as any sample user with the password 123.</p>
                    <button type="button" className="btn sampleBtn" onClick={() => handleNavigate()}>
                        <MdPerson />
                        Sign in as Sample User
                    </button>                        
                </div>
            </div> */}
        </Sider>
    );
};

export default Sidebar;
