import { configureStore } from "@reduxjs/toolkit";
import {authApi} from "./slices/userSlice";
import {productSlice} from "./slices/productSlice";
import { patientSlice } from "./slices/patientSlice";
import authReducer from "./slices/authSlice";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from "@reduxjs/toolkit/query";
import { encryptTransform } from "redux-persist-transform-encrypt";
import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist/es/constants';
  const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptTransform({ secretKey: "userType367ewuea$@@" })],
  }
  const persistedReducer = persistReducer(persistConfig, authReducer )
  export const store = configureStore({
    reducer: {
      persistedReducer,
      [authApi.reducerPath]: authApi.reducer,
      [productSlice.reducerPath]: productSlice.reducer,
      [patientSlice.reducerPath]: patientSlice.reducer,
  
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(authApi.middleware,productSlice.middleware,patientSlice.middleware),
  });
  setupListeners(store.dispatch)
  export const persistor = persistStore(store)