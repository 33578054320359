import React, { useState, useEffect, useRef } from 'react';
import { Table, Input } from "antd";
import { useGetAppointmentMutation } from '../../redux/slices/patientSlice';
import moment from 'moment';

const Appointment = () => {
    const [getAppointment, { isLoading }] = useGetAppointmentMutation();
    const [appointmentData, setappointmentData] = useState([]);
    const [filteredAppointmentData, setFilteredAppointmentData] = useState([]);
    const hasRunEffect = useRef(false);

    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
    
        getAppointment()
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.id,
                    patient_name: item.patient_name ? item.patient_name : "-",
                    service_category: item.service_category,
                    event_type: item.event_type,
                    service_title: item.service_title,
                    booked: item.online_versus_inhouse,
                    booktime: moment(item.created_at).format('MM/DD/YY hh:mm:ss A'),
                    lastseen: moment(item.updated_at).format('MM/DD/YY'),
                    status: item.booking_status,
                }));
                setappointmentData(formattedData);
                setFilteredAppointmentData(formattedData);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }, [getAppointment]);

    const tableColumns = [
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            key: "patient_name",
            sorter: (a, b) => a.patient_name.length - b.patient_name.length,
        },
        {
            title: "Booking",
            dataIndex: "booked",
            key: "booked",
            sorter: (a, b) => a.booked.length - b.booked.length,
        },
        {
            title: "Event Type",
            dataIndex: "event_type",
            key: "event_type",
            sorter: (a, b) => a.event_type.length - b.event_type.length,
        },
        {
            title: "Service Title",
            dataIndex: "service_title",
            key: "service_title",
            sorter: (a, b) => a.service_title.length - b.service_title.length,
        },
        {
            title: "Category",
            dataIndex: "service_category",
            key: "service_category",
            sorter: (a, b) => a.service_category.length - b.service_category.length,
        },
        {
            title: "Booking Time",
            dataIndex: "booktime",
            key: "booktime",
            sorter: (a, b) => a.booktime.length - b.booktime.length,
        },
        {
            title: "Last Updated",
            dataIndex: "lastseen",
            key: "lastseen",
            sorter: (a, b) => a.lastseen.length - b.lastseen.length,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
    ];

    const handleAppointmentSearch = (event) => {
        const searchText = event.target.value.toLowerCase().trim();
        const filteredData = appointmentData.filter((item) => {
            return (
                item.event_type.toLowerCase().includes(searchText) ||
                item.status.toLowerCase().includes(searchText) ||
                item.service_category.toLowerCase().includes(searchText) ||
                item.booktime.toLowerCase().includes(searchText) ||
                item.booked.toLowerCase().includes(searchText)||
                item.patient_name.toLowerCase().includes(searchText) || 
                item?.service_title?.toLowerCase().includes(searchText) ||
                item?.status?.toLowerCase().includes(searchText) ||
                item?.lastseen?.toLowerCase().includes(searchText)
            );
        });
        setFilteredAppointmentData(filteredData);
    };

    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Appointments List</h4>
                            <Input
                                placeholder="Search here.."
                                onChange={handleAppointmentSearch}
                                className="homesearchInput"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper table-responsive">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredAppointmentData}
                                pagination={true}
                                loading={isLoading}
                                showSorterTooltip={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Appointment;
