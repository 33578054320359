import React, { useState, useEffect, useRef } from 'react';
import { Table, Spin } from 'antd';
import { FaUserCircle  } from "react-icons/fa";
import { FaHospitalUser, FaHandHoldingMedical, FaWheelchair } from "react-icons/fa6";
import { TbReportMedical } from "react-icons/tb";
import { BiLogOutCircle } from "react-icons/bi";
import { logout } from '../../redux/slices/authSlice';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAppointmentMutation, useGetPatientMutation } from '../../redux/slices/patientSlice';
import { useGetProductMutation } from '../../redux/slices/productSlice';
import moment from 'moment';

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [patientcount, setpatientcount] = useState(0);
    const [getProduct, { isLoading: isProductLoading }] = useGetProductMutation();
    const [getPatient, { isLoading: isPatietLoading }] = useGetPatientMutation();
    const [getAppointment, { isLoading: isAppointmentLoading }] = useGetAppointmentMutation();
    const [lowStockData, setlowStockData] = useState([]);
    const [expiredMedicineData, setexpiredMedicineData] = useState([]);
    const [upcomingPatient, setupcomingPatient] = useState([])
    const [patientsinweek, setpatientsinweek] = useState([]);
    const [visitedPatientCount, setvisitedPatientCount] = useState(0)
    const hasRunEffect = useRef(false);

    const getPatientData = async () => {
        try {
            const res = await getPatient();
            setpatientcount(res.data.length);
        } catch (error) {
            console.error(error, 'error');
        }
    }
    const getProductData = async () => {
        try {
            const res = await getProduct();
            const threeMonthsAgo = moment().subtract(3, 'months');
            const lowStocked = res.data
                .filter(item => item.quantity < 10)
                .map(item => ({
                    key: item.id,
                    name: item.item_name,
                    image: item.image,
                    category: item.category.name,
                    price: item.price,
                    stock: item.quantity,
                    company: item.company_name ? item.company_name : "N/A"
                }));
            const expiredStocked = res.data
                .filter(item => {
                    const createdAtDate = moment(item.created_at);
                    return createdAtDate.isBefore(threeMonthsAgo);
                })
                .map(item => ({
                    key: item.id,
                    name: item.item_name,
                    image: item.image,
                    category: item.category.name,
                    company: item.company_name
                }));
            setlowStockData(lowStocked)
            setexpiredMedicineData(expiredStocked)
        } catch (error) {
            console.error("error", error);
        }
    }
    const getAppointments = async () => {
        try {
            const res = await getAppointment();
            const upcomingCustomer = res.data
                .filter(item => item.booking_status == "Service Completed")
                .map(item => ({
                    key: item.id,
                    customerName: item.patient_name,
                    customerEmail: item.patient.email
                }));
            setupcomingPatient(upcomingCustomer)
            const totalPatientVisited = res.data
                .filter(item => item.booking_status == "Service Completed" || item.booking_status == "Accepted" || item.booking_status == "Confirmed" || item.booking_status == "Awaiting Confirmation");
            setvisitedPatientCount(totalPatientVisited.length)
            const patientInWeek = res.data.filter(item =>
                moment(item.updated_at).isAfter(moment().subtract(7, 'days'))
            );
            setpatientsinweek(patientInWeek.length)
        } catch (error) {
            console.error(error, 'error');
        }
    }
    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        getPatientData();
        getProductData();
        getAppointments();
    }, []);
    const lowStockColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={record.image}
                        alt={text}
                        style={{ width: 50, height: 50, marginRight: 20, borderRadius: '5px' }}
                    />
                    <span style={{ textTransform: 'capitalize', fontWeight: 600, }}>{text}</span>
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Company Name',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
    ];


    const UpcomingProspects = [
        {
            title: 'Name',
            dataIndex: 'customerName',
            key: 'customerName',

        },
        {
            title: 'Email',
            dataIndex: 'customerEmail',
            key: 'customerEmail',
        },
    ];
    const expiredMedicineColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={record.image}
                        alt={text}
                        style={{ width: 50, height: 50, marginRight: 20, borderRadius: '5px' }}
                    />
                    <span style={{ textTransform: 'capitalize', fontWeight: 600, }}>{text}</span>
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Company Name',
            dataIndex: 'company',
            key: 'company',
        },
    ];
    const handleLogout = () => {
        dispatch(logout());
        navigate("/login");
    }
    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Dashboard</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleLogout()}
                                >
                                    <BiLogOutCircle  /> SIGN OUT
                                </button>
                                <FaUserCircle fontSize={35} onClick={ ()=>navigate("/my-account")} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className='leftSection'>
                                <div className="dashIcon">
                                    <FaHospitalUser />
                                </div>
                                <h5>Total Patients</h5>
                            </div>

                            <div className="dashboardContent">

                                <span>{isPatietLoading ? <Spin /> : patientcount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className='leftSection'>
                                <div className="dashIcon">
                                    <FaWheelchair />
                                </div>
                                <h5>Patients Visited in a week</h5>
                            </div>
                            <div className="dashboardContent">
                                <span>{isAppointmentLoading ? <Spin /> : patientsinweek}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className='leftSection'>
                                <div className="dashIcon">
                                    <FaHandHoldingMedical />
                                </div>
                                <h5>Total Patients in 90 Days</h5>
                            </div>
                            <div className="dashboardContent">
                                <span>{isAppointmentLoading ? <Spin /> : visitedPatientCount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className='leftSection'>
                                <div className="dashIcon">
                                    <TbReportMedical />
                                </div>
                                <h5>Total Upcoming Prospects</h5>
                            </div>
                            <div className="dashboardContent">
                                <span>{isAppointmentLoading ? <Spin /> : upcomingPatient.length}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <dic className="row">
                    <div className="col-md-6 mb-4">
                        <div className='card dashboardCard'>
                            <div className='card-header'>
                                <h4 className='card-title'>Upcoming Prospects</h4>
                            </div>
                            <div className='card-body'>
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={upcomingPatient}
                                        columns={UpcomingProspects}
                                        loading={isAppointmentLoading}
                                        pagination={{ pageSize: 5, showSizeChanger: false }}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='card dashboardCard'>
                            <div className='card-header'>
                                <h4 className='card-title'>Expired Medicine</h4>
                            </div>
                            <div className='card-body'>
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={expiredMedicineData}
                                        columns={expiredMedicineColumns}
                                        loading={isProductLoading}
                                        pagination={{ pageSize: 5, showSizeChanger: false }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='card dashboardCard'>
                            <div className='card-header'>
                                <h4 className='card-title'>Low stock Medicine</h4>
                            </div>
                            <div className='card-body'>
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={lowStockData}
                                        columns={lowStockColumns}
                                        loading={isProductLoading}
                                        pagination={{ pageSize: 5, showSizeChanger: false }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </dic>
            </div>
        </div>
    );
}

export default Dashboard;